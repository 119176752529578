import React, { Component } from 'react';

class Hero extends Component {
    render() {
        return (
            <section className="section skillTree">
                <div className="skillTree__timeline">
                    <div className="circle"><i className="fas fa-sun"></i></div>
                    <div className="line"></div>
                    <div className="future"><i className="fas fa-map-signs"></i></div>
                    <div class="bottom">
                        <p>What's next?</p>
                    </div>

                    <div className="leftSide">
                        <div className="skillTree__block">
                            <a href="http://www.lindeskolan.se/" target="_blank" rel="noopener noreferrer">IT Program, Lindeskolan</a>
                            <p className="year">2004 – 2007</p>
                            <div className="dot">
                                <i className="fas fa-graduation-cap"></i>
                                {/* <span className="dotInfo">
                                    <p>Computer focused progam (high school level) with main focus on web design.</p>
                                </span> */}
                            </div>
                        </div>

                        <div className="skillTree__block">
                            <a href="https://www.oru.se/utbildning/kurs--och-utbildningsplaner/utbildningsplaner/utbildningsplaner-fran-varterminen-2011-till-och-med-varterminen-2019/utbildningsplan/?format=xml&epi=j&sprak=sv&termin=20122&programkod=HDM1K" target="_blank" rel="noopener noreferrer">Digital Media Design, ÖRU</a>
                            <p className="year">2009 – 2012</p>
                            <div className="dot">
                                <i className="fas fa-graduation-cap"></i>
                                {/* <span className="dotInfo">
                                    <p>Bachelor degree in Media and Communication studies.</p>
                                </span> */}
                            </div>
                        </div>


                        <div className="skillTree__block">
                            <a href="https://www.strateg.se/" target="_blank" rel="noopener noreferrer">Strateg Marketing</a>
                            <p className="year">2015 – present</p>
                            <div className="dot">
                                <i className="fas fa-briefcase"></i>
                                {/* <span className="dotInfo">
                                    <p>My current job as a (front-end) web developer.</p>
                                </span> */}
                            </div>
                        </div>

                    </div>
                    <div className="rightSide">
                        <div className="skillTree__block -right">
                            <a href="https://prologkb.se/" target="_blank" rel="noopener noreferrer">Prolog KB</a>
                            <p className="year">2007 – 2009</p>
                            <div className="dot">
                                <i className="fas fa-briefcase"></i>
                                {/* <span className="dotInfo">
                                    <p>My first job after graduation.<br /> "News paper girl" <i className="fas fa-hand-peace"></i></p>
                                </span> */}
                            </div>
                        </div>

                        <div className="skillTree__block -right">
                            <a href="http://www.strandsgrafiska.se/ " target="_blank" rel="noopener noreferrer">Strands Grafiska, Printing Office</a>
                            <p className="year">2013 – 2014</p>
                            <div className="dot">
                                <i className="fas fa-briefcase"></i>
                                {/* <span className="dotInfo">
                                    <p>Gave me great experience with printing, mostly sublimation printing.</p>
                                </span> */}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}

export default Hero;
