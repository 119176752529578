import React, { Component } from 'react';

import './styles/main.scss';

import Hero from './components/Hero';
import Logo from './components/Logo';
import Second from './Second';
import Section from './Section';

class Home extends Component {

  componentDidMount() {
    const pointDown = document.getElementById('point-down');
    if (pointDown) {
      pointDown.addEventListener('click', function () {
        document.querySelector('#Second').scrollIntoView({
          behavior: 'smooth'
        });
      });
    }
  }

  render() {
    return (
      <>
        <div className="snapWrap">
          <Logo />
          <Section id="Hero">
            <Hero />
          </Section>

          <Section id="Second">
            <Second />
          </Section>
        </div>
      </>
    );
  }
}

export default Home;
