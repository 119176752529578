import styled from 'styled-components';

const Section = styled.section`
    scroll-snap-align: start;
    display: block;
    overflow: auto;
    width: 100%;
    height:100%;
    min-height: 100vh;
    padding: 0;    
`;

export default Section;
