import React, { Component } from 'react';
import './styles/main.scss';

import SkillTree from './components/Skilltree';

class Work extends Component {
    render() {
        return (
            <>
                <a href="/" className="logo -white" id="logo">
                    mollberg.io
                </a>
                <section className="section">
                    <div className="work">

                        <SkillTree />

                        {/* <div className="skills">
                            <h2 className="title">/ What I know</h2>
                            <div className="skills__block">
                                <h3 className="title">Programming language</h3>
                                <ul>
                                    <li>HTML5</li>
                                    <li>CSS3</li>
                                    <li>SASS, SCSS</li>
                                    <li>React</li>
                                    <li>Javascript</li>
                                    <li>PHP</li>
                                </ul>
                                <h3 className="title">Special skills</h3>
                                <ul>
                                    <li>E-mail Development</li>
                                </ul>
                                <h3 className="title">CMS knowledge</h3>
                                <ul>
                                    <li>Wordpress</li>
                                    <li>Sitevision</li>
                                </ul>
                                <h3 className="title">Marketing automation</h3>
                                <ul>
                                    <li>Active Campaign</li>
                                    <li>Mailchimp</li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </section>
            </>
        );
    }
}

export default Work;
