import React, { Component } from 'react';
// import { Link } from "react-router-dom";

class Second extends Component {
    render() {
        return (
            <section className="section second" id="second" >
                <p className="title">
                    Joanna is my name, don’t  wear it out.
                <br />
                    <span className="mobileSpan">I do web development stuff.
                    <br />
                        <i className="fas fa-code"></i></span>
                </p>

                <div className="socialLinks">
                    <a href="https://www.linkedin.com/in/joanna-m%C3%B6llberg-471b1157/" className="link -neg">LinkedIn <i className="fab fa-linkedin"></i></a>
                    <a href="https://www.behance.net/joannamollberg" className="link -neg">Behance <i className="fab fa-behance-square"></i></a>
                    <a href="https://github.com/mollberg" className="link -neg">Github <i className="fab fa-github-square"></i></a>
                    <a href="https://twitter.com/JoannaMollberg" className="link -neg">Twitter <i className="fab fa-twitter-square"></i></a>
                </div>

                {/* <Link to="/Work" className="button -neg">Check it out <i className="far fa-hand-point-right"></i></Link> */}
            </section >
        );
    }
}

export default Second;
