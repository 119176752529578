import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import Home from './Home';
import Work from './Work';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route } from "react-router-dom";

// ReactDOM.render(<Home />, document.getElementById('root'));
ReactDOM.render(
    <Router>
        <Route exact path="/" component={Home} />
        <Route path="/Work" component={Work} />
    </Router>,
    document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
