import React, { Component } from 'react';

class Hero extends Component {
  render() {
    return (
      <section className="hero">
        <h1 className="title -bold">
          “You’re only given a little spark of madness.<br />
          You mustn’t lose it.”
          <span className="quoteAuthor">– Robin Williams</span>
        </h1>
        <div className="point-down" id="point-down">
          <a href="#Second">
            <i className="far fa-hand-point-down"></i>
          </a>
        </div>
      </section>
    );
  }
}

export default Hero;
