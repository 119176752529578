import React, { Component } from 'react';

class Logo extends Component {
    render() {

        window.addEventListener('scroll', function () {
            const second = document.getElementById('Second');
            const logotype = document.getElementById('logo');
            const bounding = second.getBoundingClientRect();

            if (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            ) {
                logotype.classList.add('-white');
            } else {
                logotype.classList.remove('-white');
            }
        });

        return (
            <>
                <a href="/" className="logo" id="logo">
                    mollberg.io
                </a>
            </>
        );
    }
}

export default Logo;
